.App {
  text-align: center;
}


.App-header {
  width: 100%;
  padding: 0.5rem 0;
  font-size: calc(2px + 2vmin);
  margin-bottom: 3vh;
  border-bottom: ridge;
  text-align: center;
  text-justify: inter-word;
}

.App-link {
  color: #61dafb;
}

.texto-aviso{
  font-size: calc(2px + 2vmin);
  margin-bottom: 0px;
}

.tarjeton{
  margin-bottom: 3vh;
  padding: 2vh 2vh 2vh 2vh;
  margin-right: 2vh;
  margin-left: 2vh;
}

.texto-rojo{
  font-size: calc(5px + 2vmin);
  color:crimson;
}



.texto-pequeño{
  color: #333;
  font-size: calc(2px + 2vmin);
}

.text-descrip{
  font-size: 1vw;
  color: #83928c;
}

.boton-flotante{
  position: fixed;
  top:20%;
  left:85%;
  transition: transform .7s ease-in-out;
}
.boton-flotante:hover{
  transform: rotate(100deg);
}

.chatHeader {
  border-radius: 25%;
  padding-left: 5%;
  margin-top: -5%;
  padding-top: 5%;
  background: #dee7d2;
  width: 100%;
  color: #44493d;
}

.titulote2 {
  
  text-transform: uppercase;
  font-size: 4vh;
  color: #83928c;
  text-shadow: 0 8px 9px #83928c, 0px -2px 1px #83928c;
  font-weight: bold;
  text-align: center;
}

.titulote {
  
  text-transform: uppercase;
  color: #dee7d2;
  text-shadow: 0 8px 9px #dee7d2, 0px -2px 1px #dee7d2;
  font-weight: bold;
  text-align: center;
  margin-top: 5%;
}

.subtitulote {

  text-transform: uppercase;
  color: #dee7d2;
  text-shadow: 0 8px 9px #dee7d2, 0px -2px 1px #dee7d2;
  font-weight: bold;
  text-align: center;
  margin-top: 2%;
}

.dot-online {
  width: 12px;
  height: 12px;
  background-color: rgb(149, 212, 22);
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset rgb(149, 212, 22) 0 -1px 9px, rgb(149, 212, 22) 0 2px 12px;
}


.dot-pendiente {
  width: 12px;
  height: 12px;
  background-color: rgb(212, 145, 22);
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset rgb(212, 145, 22) 0 -1px 9px, rgb(212, 145, 22) 0 2px 12px;
}

.boton-sombra1{
  -webkit-box-shadow: 0px 0px 0px 5px #3dc28f, 0px 0px 0px 10px #fff, 0px 0px 0px 15px #dee7d2, -10px 0px 13px 10px #000000, 10px 0px 13px 10px #000000, 5px 5px 15px 5px rgba(222,231,210,0);  
  box-shadow: 0px 0px 0px 5px #3dc28f, 0px 0px 0px 10px #fff, 0px 0px 0px 15px #dee7d2,  -10px 0px 13px 10px #000000, 10px 0px 13px 10px #000000, 5px 5px 15px 5px rgba(222,231,210,0);
  margin-bottom: 5vh;
  margin-top: 5vh;
}