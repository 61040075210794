.NavbarLayout {
  width: 100%;
  height: 10%;
  background-color: #3dc28f;
  background: linear-gradient(to right, #25e1d4,#3dc28f);
  font-size: calc(10px + 4vmin);
  padding-bottom: 10px;
}

.FooterLayout {
  width: 100%;
  height: 11%;
  color: white;
  background-color: #3dc28f;
  background: linear-gradient(to right, #25e1d4,#3dc28f);
  font-size: 100%;
  padding-top: 8px;
}

.subtitulo{
  color: #fff;
  text-align: center;
}

.logo-empresa{
  height: auto;
  width: 95%;
}

.rcorners1 {
  margin-left: 10%;
  border-radius: 25%;
  padding-left: 3%;
  padding-top: 6%;
  padding-bottom: 1px;
  margin-top: -8%;
  background: #ffffffaa;
  width: 25vh;
}
