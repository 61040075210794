.boton-sombra{
    -webkit-box-shadow: 0px 0px 0px 5px #3dc28f, 0px 0px 0px 10px #fff, 0px 0px 0px 15px #dee7d2, -10px 0px 13px 10px #000000, 10px 0px 13px 10px #000000, 5px 5px 15px 5px rgba(222,231,210,0);  
    box-shadow: 0px 0px 0px 5px #3dc28f, 0px 0px 0px 10px #fff, 0px 0px 0px 15px #dee7d2,  -10px 0px 13px 10px #000000, 10px 0px 13px 10px #000000, 5px 5px 15px 5px rgba(222,231,210,0);
    margin-right: 5vh;
    margin-left: 5vh;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.titulote {
  
    text-transform: uppercase;
    font-size: 5vh;
    color: #dee7d2;
    text-shadow: 0 8px 9px #83928c, 0px -2px 1px #83928c;
    font-weight: bold;
    text-align: center;
    margin-top: 5%;
  }