
  
  .image-perfil1 {
    text-align: center;
    border: 5px solid #dee7d2;
    width: 200px;
    /* Container's dimensions */
    
    height: 200px;
    -webkit-border-radius: 75%;
    -moz-border-radius: 75%;
    box-shadow: 0 0 0 1px #eee;
    background: url("https://images.unsplash.com/reserve/ysPfhVSzSP2m629CW0mw_selfPortrait.jpg?fit=crop&fm=jpg&h=700&q=80&w=1225") center center no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -30px;
    align-content: center;
  }
  
  .image-perfil1:hover {
    box-shadow: 0px 5px 20px .9px #25e1d4;
  }
  
  .image-perfil1:hover {
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
    -webkit-transition: 1.6s ease-in-out;
    transition: 1.6s ease-in-out;
  }
  
  .image-perfil1:hover + .detalles1{
    display: block;
  }



  .image-perfil2 {
    text-align: center;
    width: 200px;
    border: 5px solid #dee7d2;
    /* Container's dimensions */
    
    height: 200px;
    -webkit-border-radius: 75%;
    -moz-border-radius: 75%;
    box-shadow: 0 0 0 1px #eee;
    background: url("https://images.unsplash.com/reserve/ysPfhVSzSP2m629CW0mw_selfPortrait.jpg?fit=crop&fm=jpg&h=700&q=80&w=1225") center center no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -30px;
    align-content: center;
  }
  
  .image-perfil2:hover {
    box-shadow: 0px 5px 20px .9px #3dc28f;
  }
  
  .image-perfil2:hover {
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
    -webkit-transition: 1.6s ease-in-out;
    transition: 1.6s ease-in-out;
  }
  
  .image-perfil2:hover + .detalles2{
    display: block;
  }
  

  

  .acordeon-perfil{
      margin-bottom: 10%;
      margin-top:10%;
  }

  .fondo-colorido{
    background:linear-gradient(to right, #25e1d4,#3dc28f);
    color:white;
  }

  .fondo-opaco{
    background: #dee7d2;
  }

  
  .text-descrip{
    margin-left:10%;
     width:80%;
    margin-top: 1%;
    font-size: 2vh;
    color: #83928c;
  }

  .text-titulo{
    margin-left:10%;
     width:80%;
    margin-top: 10%;
    font-size: 4vh;
    font-weight: 400;
    color: #242c28;
  }

  .detalles1{
    display: none;
  }

  .detalles2{
    display: none;
  }
  
  .contenedor1{
    box-shadow: 0px 5px 20px .9px #25e1d4;
  }
   .contenedor2{
    box-shadow: 0px 5px 20px .9px #3dc28f;
  }
  
  
